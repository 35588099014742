import React from "react";
import styled from "styled-components";
import Item from "./__partials/Item";
import { useSelector } from "react-redux";
import ContentType from "../ContentType";
import { Checkbox, Skeleton } from "antd";
import APIVersion from "../APIVersion";

const Accordion = ({ allTitle, onSelectAll, itemCallBack }) => {
  const { status, isSimple, validations, selectedItems } = useSelector(
    (state) => state.data
  );
  const isLoading = status === "loading";

  if (isLoading) return <StyledSkeleton paragraph={{ rows: 8 }} active />;

  let allCases = [];

  if (isSimple) {
    allCases = validations.filter(r => r.IsSimple).map((row) => row.TestCaseDetails).flat();
  } else {
    allCases = validations.map((row) => row.TestCaseDetails).flat();
  }

  const indeterminate =
    selectedItems.length !== 0 && allCases.length !== selectedItems.length;

  const checked = allCases.length === selectedItems.length;

  return (
    <>
      <APIVersion />

      <ContentType />

      <SelectAllTitle>
        {allTitle}
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onChange={onSelectAll}
        />
      </SelectAllTitle>

      {validations.map((item) => {
        if (isSimple) {
          if (item.IsSimple) {
            return (
              <Item
                callBack={itemCallBack}
                key={item.SectionName}
                data={item}
              />
            );
          } else {
            return null;
          }
        }

        return (
          <Item callBack={itemCallBack} key={item.SectionName} data={item} />
        );
      })}
    </>
  );
};

export default Accordion;

const SelectAllTitle = styled.div`
  padding-left: 16px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;

const StyledSkeleton = styled(Skeleton)`
  h3 {
    height: 33px !important;
  }
  li {
    width: 100% !important;
    height: 54px !important;
  }
`;
