import { Radio } from "antd";
import { V_5, V_3, ApiVersionEnum } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setApiVersion } from "../../features/data/dataSlice";
import styled from "styled-components";

const APIVersion = () => {
  const { apiVersion } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(setApiVersion(e.target.value));
  };

  return (
    <StyledRadioGroup onChange={onChange} value={apiVersion}>
      <StyledRadio value={V_5}>{ApiVersionEnum[V_5]}</StyledRadio>
      <StyledRadio value={V_3}>{ApiVersionEnum[V_3]}</StyledRadio>
    </StyledRadioGroup>
  );
};

export default APIVersion;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const StyledRadio = styled(Radio)`
  padding: 12px 16px;
  margin-right: 0;

  &:first-child {
    border-bottom: 1px solid #dedede;
  }
`;
